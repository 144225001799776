import { LitElement, html, css } from 'lit-element';
import axios from 'axios';

class Files extends LitElement {
    constructor() {
        super();

        this.screen = 'list';

        this.files = [];
    }
    static get properties() {
        return {
            api: {type: String},
            bucket: {type: String},
            region: {type: String},
            screen: {type: String},
            files: { type: Array },
            hasMore: { type: Boolean },
        };
    }
    static get styles() {
        return css`
            .container {
                width: 100%;
                max-width: 867px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 15px -5px;
                margin: 20px auto;
                border-radius: 5px;
            }
            table {
                width: 100%;                
            }
            table a, table a:hover, table a:visited {
                color: inherit;
                text-decoration: none;                
            }
            table a:hover {
                text-decoration: underline;                
            }            
            tr {
                background-color: #fff;
                color: #333;
            }
            th.id, td.id {
                text-align: center;
            }
            td, th {
                padding: 10px 5px;
            }
            .controls {
                display: flex;
                padding: 10px 5px;
                background-color: #eee;
            }
            .title {
                color: #666;
                font-weight: bold;
                padding: 10px;
                background-color: #eee;
            }
            .no-files {
                text-align: center;
                color: #999;
            }
            .tab {
                display: none;
            }
            .active {
                display: block;
            }
            form {
                padding: 20px;
                color: #333;
                display: flex;
                flex-direction: column;
            }
            form label {
                font-size: 0.9em;
                font-weight: bold;
            }
            form input, form textarea {
                border-radius: 3px;
                border: solid 1px #999;
                padding: 3px;
                margin-top: 5px;
                -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                box-sizing: border-box; 
            }

            textarea {
                resize: none;
            }

            .controls input {
                flex: 1;
                border-radius: 3px;
                border: solid 1px #999;
                padding: 3px;
                width: 450px;
                margin: 0 5px;
            }

            .controls.right {
                display: flex;
                justify-content: flex-end;
            }

            button {
                cursor: pointer;
                font-weight: bold;
                margin: 0 5px;
                background-color: #ccc;
                transition: background 0.23s ease 0s;
                font-size: 11px;
                padding: 3px 10px;
                border-width: initial;
                border-style: none;
                border-color: initial;
                border-image: initial;
                border-radius: 10px;
            }

            button.danger {
                background-color: #d81b60;
                color: #fff;
            }
            button.primary {
                background-color: #2286c3;
                color: #fff;
            }

            #name {
                width: 100%;
            }
            #description {
                width: 100%;
                height: 100px;
            }
        `;
    }
    async firstUpdated() {
        this.shadowRoot.getElementById('uploader').addEventListener('fileSent', (e) => this._fileSent(e.detail));
        await this._fetchFiles();
    }
    render() {
        return html`<div class="container">
            <div id="list" class="tab ${this.screen==='list' ? 'active' : ''}">
                <div class="controls">
                    <button class="danger" @click=${this._add}>ADD</button> 
                    <button @click=${this._fetchFiles}>REFRESH</button>
                    <input type="text" placeholder="Filtro por palavra..." />
                    <button>Filtros</button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Título</th>
                            <th>Tipo</th>
                            <th>Descrição</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                    ${this.files.length===0 ? html`<tr>
                            <td colspan="5" class="no-files">Nenhum arquivo encontrado!</td>
                        </tr>` : null}
                    ${this.files.map(file => { 
                        const fileurl = `https://${this.bucket}.s3.${this.region}.amazonaws.com/${file.url}`;
                        return html`<tr>
                            <td class="id">${file.id}</td>
                            <td> <a href="${fileurl}" target="_blank">${file.name}</a></td>
                            <td>${file.type}</td>
                            <td>${file.description}</td>
                            <td>
                                [E][R]
                                <a href="${fileurl}" target="_blank">[D]</a>
                            </td>
                        </tr>`
                    })}
                    </tbody>
                </table>

                <div class="controls">
                    ${this.hasMore ? html`[More]` : null}
                </div>
            
            </div>

            <div id="manage" class="tab ${this.screen==='manage' ? 'active' : ''}">
                <div class="title">Adicionar arquivo</div>

                <form>
                    <engajados-s3-upload 
                        id="uploader" 
                        bucket=${this.bucket}
                        region=${this.region}
                        api=${this.api} 
                        pre-signed 
                        send-button="no" 
                        max-files="1" 
                        dragMessage="Arraste um arquivo ou clique aqui"
                        ></engajados-s3-upload>

                
                    <p>
                        <label>Título</label><br/>
                        <input id="name" type="text" />
                    </p>
                    <p>
                        <label>Descrição</label><br/>
                        <textarea id="description"></textarea>
                    </p>                
                </form>
                
                <div class="controls right">
                    <button class="primary" @click=${this._save}>GRAVAR</button>
                    <button @click=${this._cancel}>CANCELAR</button>
                </div>
            </div>
        </div>`;
    }
    async _fetchFiles() {
        if(!this.api) {
            throw 'API attribute is Empty!';
        }

        const response = await fetch(`${this.api}list`);
        const { files, hasMore } = await response.json();
        this.files = files;
        this.hasMore = hasMore;
    }
    _add() {
        this.screen = 'manage';
    }
    _cancel() {
        this.screen = 'list';
    }
    _save() {
        const uploader = this.shadowRoot.getElementById('uploader');
        const title = this.shadowRoot.getElementById('name').value;

        if(uploader.getFileCount() === 0) {
            alert('Você deve inserir um ARQUIVO!'); // TODO: layout
            return;
        }
        if(title.value === '') {
            alert('Você deve preencher o NOME!'); // TODO: layout
            return;
        }

        uploader.send(); // TODO: best practices
    }
    async _fileSent(file) {
        const title = this.shadowRoot.getElementById('name').value;
        const description = this.shadowRoot.getElementById('description').value;

        const { name, type } = file;
        
        /*const result = */ await axios.post(`${this.api}file`, {
            name: title,
            description,
            url: name,
            type,
        });

        this.screen = 'list';
        this._fetchFiles();

        this._resetForm();
    }
    _resetForm() {
        const uploader = this.shadowRoot.getElementById('uploader');

        this.shadowRoot.getElementById('name').value = '';
        this.shadowRoot.getElementById('description').value = '';
        uploader.clean()
    }
}

customElements.define('engajados-s3-files', Files);