import { LitElement, html, css } from 'lit-element';

import { SimpleDropzone } from 'simple-dropzone';
import axios from 'axios';

import uuid from 'uuid/v1';

class Upload extends LitElement {
    constructor() {
        super();

        this.files = [];

        this.showError = false;

        this.dragMessage = 'Drag files or Click Here!';
    }
    static get properties() {
        return {
            bucket: {type: String},
            region: {type: String},
            api: { type: String },
            files: { type: Array },
            "max-files": { type: String },
            "send-button": { type: String },
            dragMessage: { type: String },
            showError: { type: Boolean },
            
            folder: { type: String },
            prefix: { type: String },            
        };
    }
    static get styles() {
        return css`
            .container {
                width: 100%;
                max-width: 867px;
                margin: 0px auto;
            }
            #dropzone {
                position: relative;
                width: 100%;
                margin-top: 20px;
                min-height: 100px;
                background-color: #ccc;
                border-radius: 4px;
                cursor: pointer;
                color: #333;
            }
            #dropzone:hover {
                background-color: #bbb;
            }
            #dropzone ul {
                list-style-type: none;
                padding: 10px;
                margin: 0;
            }
            #dropzone li {
                font-size: 0.85em;
                position: relative;
                width: 100%;
                border: solid 1px #bbb;
                border-radius: 3px;
                padding: 0;
                margin-bottom: 5px;
                cursor: default;
            }
            #dropzone:hover li {
                border-color: #aaa;
            }
            #dropzone li .file_data {
                max-width: 350px;
                overflow: hidden;
                white-space: nowrap;
            }
            #dropzone li .progress {
                position: absolute;
                width: 0%;
                background-color: #999;
                border-radius: 1px;
                bottom: 0;
                height: 3px;
            }
            #dropzone img {
                width: 50px;
                border-radius: 5px;
            }
            #dropzone button {
                background-color: #aaa;
                border: none;
                border-radius: 5px;
                padding-bottom: 3px;
                margin-bottom: 3px;
                cursor: pointer;
            }
            #input[type="file"] {
                display: none;
            }
            .no-file {
                color: #999;
                width: 100%;
                text-align: center;
                margin-top: 20px;
                padding-top: 20px;
            }
            .sent {
                background-color: #362;
                opacity: 0.6;
                color: #fff;
            }
            .error {
                background-color: #932;
                opacity: 0.65;
                color: #fff;
            }
            .send-btn {
                width: 100%;
            }
            @media only screen and (max-width: 600px) {
                .container {
                    width: 80%;
                }
            }
            .alert-container {
                z-index: 1000;
                position: absolute;
                width: 100%;
                opacity: 0.5;
                background-color: red;
                border-radius: 3px;
                color: #fff;
                font-weight: bold;
                font-size: 0.85em;
                text-align: center;
            }
            .alert-container div {
                padding: 4px;
            }
        `;
    }
    firstUpdated() {
        const dropEl = this.shadowRoot.querySelector('#dropzone');
        const inputEl = this.shadowRoot.querySelector('#input');
        const dropCtrl = new SimpleDropzone(dropEl, inputEl); // this['max-files']

        dropCtrl.on('drop', ({ files }) => {

            const droppedFiles = Array.from(files);

            if(this['max-files'] && (droppedFiles.length + this.files.length > this['max-files'])) {
                this._showError();
                return;
            }
            
            droppedFiles.map(file => {
                if (this.files.filter(iFile => iFile[1].name === file[1].name).length === 0) { // verrifica se já está na lista - mesmo nome
                    file[2] = uuid();
                    file[3] = 'none';
                    this.files = [...this.files, file];
                }
            })
        });
    }
    render() {
        return html`<div class="container">
            <div id="dropzone" @click=${this._dropClicked}>
                ${this.showError ? html`<div class="alert-container">
                    <div>Você superou o limite de ${this['max-files']} arquivo!</div>
                </div>` : null}
                ${this.files.length === 0 ? html`<div class="no-file">${this.dragMessage}</div>` : ''}

                <ul>
                ${this.files.map(([_, file, ref, status]) => {

                    let imageHTML = '';

                    let size = file.size;
                    if (size < 1000) size = `${size}b`;
                    else if (size < 1000000) {
                        const converted = Math.round(size / 1000);
                        size = `${converted}Kb`;
                    } else {
                        const converted = Math.round(size / 1000000);
                        size = `${converted}Mb`;
                    }

                    if (file.type.includes('image')) {
                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                            this.shadowRoot.getElementById(ref).querySelector('img').src = reader.result;
                        });
                        reader.readAsDataURL(file);

                        imageHTML = html`<td><img /></td>`;
                    }

                    return html`<li id="${ref}" class="${this._statusClass(status)}">
                                <div class="progress"></div>
                                <table> 
                                    <tr>                           
                                        <td class="file_data"><button title="remover" @click=${(e) => this._remove(e, ref)}>x</button></td>
                                        ${imageHTML}
                                        <td class="file_data">${size}</td>
                                        <td class="file_data">${file.name}</td>
                                    </tr>
                                </table>
                            </li>`;
                })}
                </ul>
            </div>
            <div><input type="file" id="input" multiple></div>
            ${(!this['send-button'] || this['send-button']!=='no') ? html`<div><button class="send-btn" @click=${this._click}>Enviar</button></div>` : null}
        </div>`;
    }
    _showError() {
        this.showError = true;
        if(this.timer) window.clearTimeout(this.timer);
        this.timer = window.setTimeout(()=>this.showError = false, 3000);
    }
    _statusClass(status) {
        switch (status) {
            case 'sent':
                return 'sent';
            case 'error':
                return 'error';
            default:
                return '';
        }
    }
    _dropClicked() {
        this.shadowRoot.querySelector('#input').click();
    }
    _remove(e, ref) {
        e.stopPropagation();

        const [fileToRemote] = this.files.filter( file => file[2] === ref);

        if(fileToRemote[3] === 'sending') {
            console.log('cannot remove! sending...');
            return;
        }

        this.files = this.files.filter(file => file[2] !== ref);
        this.shadowRoot.querySelector('#input').value = '';
    }
    async _sendFile(theFile) {
        if(!this.api) {
            throw 'API attribute is Empty!';
        }

        const [_, file, ref] = theFile;

        let { name, type } = file;
        
        if(this.prefix) {
            name = `${this.prefix}${name}`;
        }

        if(this.folder) {
            name = `${this.folder}/${name}`;
        }

        console.log(`Sending ${name}...`);

        const { data } = await axios.get(`${this.api}get-pre-signed`, {
            params: {
                'bucket': this.bucket,
                'region': this.region,
                'filename': name,
                'filetype': type
            }
        });

        // TODO: verify error
        const signedUrl = data.url;

        const options = {
            headers: {
                'Content-Type': type
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.shadowRoot.getElementById(ref).querySelector('.progress').style.width = `${percentCompleted}%`;
            },
        };

        theFile[3] = 'sending';
        const result = await axios.put(signedUrl, file, options);

        if (result.status === 200) {
            this.dispatchEvent(new CustomEvent('fileSent', { detail: theFile[1] }));

            theFile[3] = 'sent';
            console.log(`${name} sent!`);
        } else {
            theFile[3] = 'error';
            console.log(`${name} error!`);
        }
        // concurrency?
        window.setTimeout(() => this.files = [...this.files]);
    }
    _click() {
        this.files.map(file => { if (!['sending', 'sent'].includes(file[3])) this._sendFile(file) });
    }

    getFileCount() { // TODO: best prectices
        return this.files.filter( file => (!['sending', 'sent'].includes(file[3])) ).length;
    }
    send() {
        this._click(); // TODO: best practices

        return this.files.filter( file => (!['sending', 'sent'].includes(file[3])) ).map( ([_,file]) => file.name );
    }
    clean() {  // TODO: best prectices
        this.files = [];
    }
}

customElements.define('engajados-s3-upload', Upload);